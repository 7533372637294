import usePost from '../api/usePost';
import { useState } from 'react';

function InvoiceForm({notify, visible, setVisible, setRefresh, refresh, shipmentId}) {
  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({shipment_id: shipmentId, invoice_number: '', invoice_amount: ''});

 
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value, shipment_id: shipmentId });
      console.log(formData)
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await postData('/update_martelle_shipment', formData);
      if (response) {
        setRefresh(!refresh);
        setVisible(false);
        notify('Envoi Finalisé')
      }
    };
    const handleClose = () => {
        setVisible(false);
      };
  return (
    visible && (<div id="shipment-form">
    <i id="close-shipment-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
      <label htmlFor="shipment-cost">
        <i className="fas fa-tags"></i>Numero Facture:
        </label>
        <input type="text" id="cost" name="invoice_number" value={formData.invoice_number} onChange={handleChange}/>
        <br />
        <label htmlFor="shipment-cost">
        <i className="fas fa-tags"></i>Montant Facture:
        </label>
        <input type="text" id="cost" name="invoice_amount" value={formData.invoice_amount} onChange={handleChange}/>
        <br />
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Finaliser Envoi
        </button>
      </div>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default InvoiceForm