import useFetch from '../api/useFetch';
import { useContext } from 'react';
import '../css/orders.css';
import Loading from './Loading';
import Orders from './Orders';
import SearchResultsContext from '../context/SearchTermContext';

function SearchResults({ notify }) {
    const { searchTerm } = useContext(SearchResultsContext);
    console.log('search term: ' , searchTerm)
    const { result: searchResults, isLoading, error } = useFetch(`/search_martelle_order?query=${searchTerm}`);
    console.log('search results in SearchResults.js: ' , searchResults)
    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!searchResults || searchResults.length === 0) {
        return <div>No results found</div>;
    }

    return (
        <Orders status_filter={null} notify={notify} search_orders={searchResults} />
    );
}

export default SearchResults;
