import usePost from '../api/usePost';
import { useState } from 'react';
import Loading from './Loading';
import { useNavigate } from 'react-router-dom';


function ShipmentForm({notify, visible, setVisible, setRefresh, refresh}) {
  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({weight: ''});
  const navigate = useNavigate();
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      console.log(formData)
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await postData('/create_martelle_shipment', formData);
      if (response) {
        setRefresh(!refresh);
        setVisible(false);
        notify('Envoi créé') ;
        navigate('/shipments'); 
      }
    };
    const handleClose = () => {
        setVisible(false);
      };
  return (
    visible && (<div id="shipment-form">
    <i id="close-shipment-form" className="fas fa-times" onClick={handleClose}></i>
    {!isLoading &&<>
    <form onSubmit={handleSubmit}>
      <div>
      <label htmlFor="shipment-cost">
        <i className="fas fa-tags"></i>Poids Réél:
        </label>
        <input type="text" id="cost" name="weight" value={formData.weight} onChange={handleChange}/>
        <br />
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Creer Envoi
        </button>
      </div>
    </form>
    </>}
    {isLoading && <Loading />}
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default ShipmentForm