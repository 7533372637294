import usePost from '../api/usePost';
import { useState } from 'react';

function CommentForm({notify, visible, setVisible, orders, orderId, setRefresh, refresh}) {
  let order = orders.find(order => order.id === orderId);
  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({order_id: orderId, comment: order.comment ? order.comment : ''});
  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };


  const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await postData('/create_martelle_comment', formData);
      if (response) {
        setRefresh(!refresh);
        setVisible(false);
        notify('Commentaire Enregistré')
      }
    };

  const handleClose = () => {
        setVisible(false);
      };

  return (
    visible && (<div id="comment-form">
    <i id="close-comment-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="comment">Comment:</label>
        <br />
        <textarea id="comment" name="comment" style={{ width: '300px', height: '400px' }} value={formData.comment} onChange={handleChange}/>
      </div>
      <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Enregistrer
        </button>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default CommentForm