import React from 'react'
import useFetch from "../api/useFetch";
import { useState } from 'react';
import ShipmentLine from './ShipmentLine';
import Loading from './Loading';
import Updated from '../static/img/updated.png';
import InvoiceForm from './InvoiceForm';

const Shipments = (notify) => {

    const [refresh, setRefresh] = useState(false);
    const { result: shipments, isLoading: shipmentsLoading, error: shipmentsError } = useFetch('/get_martelle_shipments',[refresh]);
    const [invoiceFormVisible, setInvoiceFormVisible] = useState(false)
    const [selectedShipmentId, setSelectedShipmentId] = useState(null);


    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const handleUpdateClick = (shipmentId) => {
        setSelectedShipmentId(shipmentId);
      };
    
    return (
        <>
        <div div className="orders">
          <div div className="title"> ENVOIS <i className="fas fa-sync" onClick={handleRefresh}></i></div>
            <div className="table">
              <div className="table-header">
                <div className="date">Date</div>
                <div className="small">Numéro de suivi</div>
                <div className="product">Cout de l'envoi</div>
                <div className="order_id">Numéro de facture</div>
                <div className="small">Montant de la facture</div>
                <div className="status">Statut</div>
              </div>
              <div className="table-body">
                {shipmentsLoading && <div div className='table-placeholder'><Loading /></div>}
                {shipmentsError && <div>{shipmentsError}</div>}
                {!shipmentsLoading &&  shipments && shipments.items.length > 0 && shipments.items.map((shipment) => <ShipmentLine  shipment_id={shipment.id} label_url={shipment.label_url} tracking_number={shipment.tracking_number}  invoice_number = {shipment.martelle_invoice_number} invoice_amount={shipment.martelle_invoice_amount} shipment_date={shipment.creation_date} shipment_cost={shipment.cost} shipment_currency={shipment.currency} status={shipment.martelle_status} setInvoiceFormVisible={setInvoiceFormVisible} handleUpdateClick={handleUpdateClick} shipmentId={selectedShipmentId}/>)}
                {!shipmentsLoading && shipments && shipments.items.length === 0 && <img src={Updated} alt="" width="200px" id='updated'/>}
              </div>
            </div>
          </div>
          {invoiceFormVisible && (<><InvoiceForm notify = {notify}  visible={invoiceFormVisible} setVisible={setInvoiceFormVisible}  setRefresh={setRefresh} refresh={refresh} shipmentId={selectedShipmentId}/><div className="bg-disabled"></div></>)}

      </>
  )
}

export default Shipments