import usePost from '../api/usePost';

const INTERNAL_STATUS = {
  a_saisir: "A Saisir",
  en_commande: "En Commande",
  a_envoyer: "A Envoyer",
  incomplete: "Incomplete",
};



const OrderLine = ({
    id,
    is_multi,
    order_date,
    product_name,
    ean,
    supplier,
    price,
    quantity,
    status,
    is_external,
    notify,
    refresh,
    setRefresh,
    openOrderUpdateForm,
    openCommentForm,
    onUpdateClick ,
    comment,
    latest_date,
    global_order_id
}) => {

  const { postData, error } = usePost();

  const updateStatus = async (status) => {
    if (status === 'unavailable') {
      const confirmUpdate = window.confirm(`Etes vous sure de vouloir noter l'ouvrage ${ean} - ${product_name.length > 50 ? product_name.substring(0, 45).concat(" (...)") : product_name} comme indisponible?`);
      if (!confirmUpdate) {
        return; // If the user cancels, exit the function
      }

    }
    const data = {order_id: id, status: status}
    const response = await postData('/update_martelle_order', data);
    if (response) {
      setRefresh(!refresh);
        notify('Commande mise à jour')
    }
    if (error) {
      notify(error)
  }
  }

  const handleEditClick = () => {
    onUpdateClick(id);
    openOrderUpdateForm();
  };

  const handleCommentClick = () => {
    onUpdateClick(id);
    openCommentForm();
  };

  return (
    <div className="table-row">
      <div className="date">{order_date.split('-').reverse().join('/')}</div>
      <div className="small">{global_order_id}</div>
      {<div className="product">{is_external && <i className="fas fa-store"></i>} {product_name.length > 50 ? product_name.substring(0, 45).concat(" (...)") : product_name}</div>}
      {<div className="order_id">{ean}</div>}
      <div className="small" style={quantity > 1 ? { color: 'red', fontWeight: 800 } : {}}>{quantity}</div>
      <div className="medium">{supplier}</div>
      <div className="price">{price} EUR</div>
      <div className="medium">{latest_date.split('-').reverse().join('/')}</div>
      <div className="status">{INTERNAL_STATUS[status]}</div>
      <div className="medium">
          {(comment && comment !== '') && <i onClick={handleCommentClick} class="fas fa-comment"></i>}
          {(!comment || comment === '') && <i onClick={handleCommentClick} class="far fa-comment"></i>}
          {is_multi && <i class="fas fa-link"></i> }
          {status!== 'a_envoyer' && <i onClick={() => updateStatus('unavailable')} className="fas fa-window-close"  style={{ color: 'red' }} title='Indisponible'></i>}
          {status === 'a_saisir' && <i onClick={() => updateStatus('en_commande')} className="fas fa-pause-circle"  style={{ color: 'blue' }} title='En Commande'></i>}
          {status !== 'a_envoyer' && <i onClick={() => updateStatus('a_envoyer')} className="fas fa-check-square"  style={{ color: 'green' }} title='A Envoyer'></i>}
          {status !== 'a_saisir' && <i onClick ={handleEditClick} className="fas fa-edit" title='Modifier'></i>}
      </div>
    </div>
  );
};

export default OrderLine;
