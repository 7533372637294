import React from 'react';
import { useContext } from 'react';
import '../css/main.css'; 
import SidebarItem from './SidebarItem';
import SidebarContext from '../context/SidebarContext';


const Sidebar = () => {
    const { collapsed, toggleCollapsed } = useContext(SidebarContext);

    const menuItems = [
        { icon: 'fas fa-shopping-cart', text: 'A SAISIR', to: '/' },
        { icon: 'fas fa-hourglass-half', text: 'EN ATTENTE', to: '/orders_waiting' },
        { icon: 'fas fa-clock', text: 'EN RETARD', to: '/orders_late' },
        { icon: 'fas fa-pause-circle', text: 'INCOMPLETES', to: '/orders_incomplete' },
        { icon: 'fas fa-check-square', text: 'A ENVOYER', to: '/orders_to_send' },
        { icon: 'fas fa-window-close', text: 'INDISPONIBLE', to: '/orders_unavailable' },
        { icon: 'fas fa-plane', text: 'ENVOIS', to: '/shipments' },
      ];

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
        <i className="fas fa-bars sidebar-btn" onClick={toggleCollapsed}></i>
        <div className={`sidebar-content ${collapsed ? 'collapsed' : ''}`}>
        {menuItems.map((item) => (
          <SidebarItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            to={item.to}
          />
        ))}
        </div>
        <div className="sidebar-bottom">
        </div>
        </div>
    );
};

export default Sidebar;
