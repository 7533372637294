import axios from 'axios';
import { useState } from 'react';
import API_BASE_URL from './config';

const usePost = (endpoint) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const postData = async (url, data) => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(`${API_BASE_URL}${url}`, data, { headers });

      if (response.status !== 200 && response.status !== 201 && response.status !== 202) {
        throw Error('Could not create the resource');
      }

      setIsLoading(false);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Refresh token logic
        const refreshToken = localStorage.getItem('refreshToken');
        const refreshResponse = await axios.post(`${API_BASE_URL}/token/refresh/`, {
          refresh: refreshToken,
        });
        localStorage.setItem('accessToken', refreshResponse.data.access);

        // Retry original request with the new access token
        return await postData(url, data);
      } else {
        setError(error.response.data.error);
        setIsLoading(false);
        throw error;
      }
    }
  };

  return { postData, isLoading, error };
};

export default usePost;
